<template>
    <div>
        <div class="app-menu navbar-menu border-0">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <!-- Light Logo-->
                <a href="javaScript:void(0);" class="logo">
                    <span class="logo-sm">
                        <img src="/public/images/mobile_sc_logo2.png" alt="" width="35" />
                    </span>
                    <span class="logo-lg">
                        <img src="/public/images/sc_new_logo.png" alt="SCLOGO" class="mt-4 mb-3" height="60" />
                    </span>
                </a>
                <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                    <i class="ri-record-circle-line"></i>
                </button>
            </div>

            <div id="scrollbar" data-simplebar class="h-100 simplebar-scrollable-y">
                <div class="container-fluid">
                    <div id="two-column-menu"></div>
                    <ul class="navbar-nav" id="navbar-nav">
                        <!-- <h5>isSuperAdmin: {{ isSuperAdmin }}</h5> -->

                        <li class="menu-title"><span data-key="t-menu">CMS</span></li>
                        <li @click="onClickMenu('dashboard')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'dashboard' }" class="nav-link menu-link" :to="{ name: 'DashboardPage' }"><i class="mdi mdi-puzzle-outline"></i> <span data-key="t-widgets">Dashboard</span> </router-link>
                        </li>

                        <li @click="onClickMenu('dynamic')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'dynamic' }" class="nav-link menu-link" :to="{ name: 'DynamicPage' }"><i class="mdi mdi-book-open-page-variant-outline"></i> <span data-key="t-widgets">Pages</span> </router-link>
                        </li>
                        <li @click="onClickMenu('contacts')" class="nav-item" v-if="isSuperAdmin">
                            <router-link :class="{ active: activeMenu === 'contacts' }" class="nav-link menu-link" :to="{ name: 'ContactsPage' }"><i class="mdi mdi-card-account-phone-outline"></i> <span data-key="t-widgets">Contacts</span> </router-link>
                        </li>
                        <li @click="onClickMenu('partner')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'partner' }" class="nav-link menu-link" :to="{ name: 'PartnerPage' }"><i class="mdi mdi-handshake-outline"></i> <span data-key="t-widgets">Partners</span> </router-link>
                        </li>
                        <li @click="onClickMenu('services')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'services' }" class="nav-link menu-link" :to="{ name: 'ServicesPage' }"><i class="mdi mdi-face-agent"></i> <span data-key="t-widgets">Services</span> </router-link>
                        </li>
                        <li @click="onClickMenu('blog')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'blog' }" class="nav-link menu-link" :to="{ name: 'AdminBlogPage' }"><i class="mdi mdi-post-outline"></i> <span data-key="t-widgets">Blogs</span> </router-link>
                        </li>
                        <li @click="onClickMenu('testimonial')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'testimonial' }" class="nav-link menu-link" :to="{ name: 'AdminTestimonialPage' }"><i class="mdi mdi-comment-quote-outline"></i> <span data-key="t-widgets">Testimonials</span> </router-link>
                        </li>
                        <li @click="onClickMenu('faq')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'faq' }" class="nav-link menu-link" :to="{ name: 'AdminFaqPage' }"><i class="mdi mdi-chat-question-outline"></i> <span data-key="t-widgets">Faq</span> </router-link>
                        </li>
                        <li @click="onClickMenu('events')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'events' }" class="nav-link menu-link" :to="{ name: 'AdminEventsPage' }"><i class="mdi mdi-account-tie-outline"></i> <span data-key="t-widgets">Events</span> </router-link>
                        </li>
                        <li @click="onClickMenu('policy')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'policy' }" class="nav-link menu-link" :to="{ name: 'PolicyPage' }"><i class="mdi mdi-clipboard-multiple"></i> <span data-key="t-widgets">Policy</span> </router-link>
                        </li>
                        <li @click="onClickMenu('department')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'department' }" class="nav-link menu-link" :to="{ name: 'DepartmentPage' }"><i class="mdi mdi-clipboard-text-multiple"></i> <span data-key="t-widgets">Department</span> </router-link>
                        </li>
                        <li @click="onClickMenu('careers')" class="nav-item">
                            <router-link :class="{ active: activeMenu === 'careers' }" class="nav-link menu-link" :to="{ name: 'CareersPage' }"><i class="mdi mdi-decagram-outline"></i> <span data-key="t-widgets">Careers</span> </router-link>
                        </li>

                        <li class="menu-title"><span data-key="t-menu">Menu</span></li>
                        <li @click="onClickMenu('administrator')" class="nav-item" v-if="isSuperAdmin">
                            <router-link :class="{ active: activeMenu === 'administrator' }" class="nav-link menu-link" :to="{ name: 'AdministratorPage' }"><i class="mdi mdi-account-tie"></i> <span data-key="t-widgets">Administrators</span> </router-link>
                        </li>
                        <li @click="onClickMenu('blueprint')" class="nav-item" v-if="isSuperAdmin">
                            <router-link :class="{ active: activeMenu === 'blueprint' }" class="nav-link menu-link" :to="{ name: 'BlueprintPage' }"><i class="mdi mdi-fingerprint"></i> <span data-key="t-widgets">Blueprints</span> </router-link>
                        </li>
                        <li class="nav-item" @click="onClickMenu('menu')">
                            <router-link  :class="{ active: activeMenu === 'menu' }" class="nav-link menu-link" :to="{ name: 'MenuPage' }"> <i class="mdi mdi-navigation-variant-outline"></i> <span data-key="t-widgets">Menu Data</span> </router-link>
                        </li>
                        <li class="nav-item" @click="onClickMenu('azure')">
                            <a href="javaScript:void(0);" :class="{ active: activeMenu === 'azure' }" class="nav-link menu-link"> <i class="mdi mdi mdi-microsoft-azure"></i> <span data-key="t-widgets">Azure</span> </a>
                        </li>
                    </ul>
                </div>
                <!-- Sidebar -->
            </div>

            <div class="sidebar-background"></div>
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
    </div>
</template>

<script>
export default {
    // props: ["admin_rec"],

    emits: ["call_navbar", "call_azure"],

    data() {
        return {
            isWeb: false,
            isPortal: false,
            activeMenu: "",
        };
    },

    beforeMount() {
        this.onDetermineActiveMenu();
    },

    methods: {
        onClickMenu(type) {
            this.activeMenu = type;

            if (type == "navbar") {
                this.$emit("call_navbar", true);
            } else if (type == "azure") {
                this.$emit("call_azure", true);
            } else {
                this.$emit("call_navbar", false);
                this.$emit("call_azure", false);
            }
        },

        onDetermineActiveMenu() {
            const pathName = this.$route.name;
            const routeMeta = this.$route.meta;
            if (pathName == "DashboardPage") {
                this.activeMenu = "dashboard";
            } else if (pathName == "AdministratorPage") {
                this.activeMenu = "administrator";
            } else if (pathName == "DynamicPage") {
                this.activeMenu = "dynamic";
            } else if (pathName == "ContactsPage") {
                this.activeMenu = "contacts";
            } else if (pathName == "PartnerPage") {
                this.activeMenu = "partner";
            } else if (routeMeta.isServices) {
                this.activeMenu = "services";
            } else if (pathName == "BlueprintPage") {
                this.activeMenu = "blueprint";
            } else if (routeMeta.isBlog) {
                this.activeMenu = "blog";
            } else if (pathName == "AdminTestimonialPage") {
                this.activeMenu = "testimonial";
            } else if (routeMeta.isEvents) {
                this.activeMenu = "events";
            } else if (routeMeta.isPolicy) {
                this.activeMenu = "policy";
            } else if (routeMeta.isDepartment) {
                this.activeMenu = "department";
            } else if (routeMeta.isCareers) {
                this.activeMenu = "careers";
            } else if (routeMeta.isMenu) {
                this.activeMenu = "menu";
            }
        },
    },

    computed: {
        admin_rec() {
            return this.$store.state.adminRecord;
        },
        isSuperAdmin() {
            if (this.admin_rec) {
                return this.admin_rec.isSuperAdmin;
            }
            return false;
        },
    },
};
</script>
