<template>
    <div>
        <div v-if="is_loading" class="loader"></div>

        <div v-if="isWeb">
            <RouterView />
        </div>

        <div v-else-if="!isPortal && !isWeb">
            <div class="auth">
                <div class="left">
                    <div>
                        <img src="/public/images/sc_new_logo.png" alt="" />

                        <!-- <p class="mt-4">Website management, simplified</p> -->
                    </div>
                </div>
                <RouterView />
            </div>
        </div>

        <div v-else>
            <div id="layout-wrapper">
                <admin-header />
                <admin-sidebar @call_navbar="onCallNavBarForm" @call_azure="onCallAzureForm" />
                <!-- MAIN CONTENT -->
                <div class="main-content">
                    <div class="page-content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col">
                                    <div class="h-100">
                                        <div class="row mb-3 pb-1">
                                            <div class="col-12">
                                                <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                                                    <div class="flex-grow-1">
                                                        <RouterView />
                                                        <NavBarConfigForm :is_click="is_navbar" @call_navbar="onCallNavBarForm" />
                                                        <azure-config-form :is_click="is_azure" @call_azure="onCallAzureForm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MAIN CONTENT -->
            </div>
        </div>
    </div>
</template>

<script>
import AdminHeader from "./admin/includes/header.vue";
import AdminSidebar from "./admin/includes/sidebar.vue";

// import NavBarConfigForm from "./admin/navbar_form.vue";
import AzureConfigForm from "./admin/azure_form.vue";

export default {
    components: { AdminHeader, AdminSidebar, AzureConfigForm },

    data() {
        return {
            isWeb: true,
            isPortal: false,
            isAdminLoggedIn: false,
            // admin_rec: {},
            is_loading: false,
            is_navbar: false,
            is_azure: false,
        };
    },
    provide() {
        return {
            // using the Arrow function to preserve 'this'
            authenticateUser: (value) => {
              //  console.log("injected function: ", value);
                if (value) {
                    this.onPopulateAdminData();
                }
            },
        };
    },
    mounted() {
        this.$eventBus.on("call_loader", () => this.callLoader(true));
        this.$eventBus.on("hide_loader", () => this.callLoader(false));

        const token = sessionStorage.getItem("admin_api_token");
        if (token) {
            this.isPortal = true;
            this.isAdminLoggedIn = true;
            this.onPopulateAdminData();
        }
    },

    methods: {
        onPopulateAdminData() {
            this.$query_admin("admin", {
                action_type: "current_admin",
            }).then((res) => {
                this.admin_rec = res.data.data.administrator[0];
                this.isPortal = true;
                this.isAdminLoggedIn = true;
                this.$store.commit("updateAdminRecord", this.admin_rec);
            });
        },

        callLoader(value) {
            this.is_loading = value;
        },

        onCallNavBarForm(val) {
            this.is_navbar = val;
        },

        onCallAzureForm(val) {
            this.is_azure = val;
        },
    },

    watch: {
        $route(to, from) {
            if (to.meta.isWeb) {
                this.isWeb = true;
            } else {
                this.isWeb = false;
            }
        },
    },
};
</script>
