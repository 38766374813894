<template>
    <header id="page-topbar">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <a href="#" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="https://picsum.photos/200" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="https://picsum.photos/200" alt="" height="17" />
                            </span>
                        </a>
                        <a href="#" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="https://picsum.photos/200" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="https://picsum.photos/200" alt="" height="17" />
                            </span>
                        </a>
                    </div>

                    <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon">
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>
                </div>

                <div class="d-flex align-items-center">
                    <div class="dropdown d-md-none topbar-head-dropdown header-item">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-search fs-22"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="header-item d-none d-sm-flex">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" data-toggle="fullscreen">
                            <i class="bx bx-fullscreen fs-22"></i>
                        </button>
                    </div>

                    <!-- <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div> -->

                    <!-- PROFILE -->
                    <div class="border-start border-success py-3 mx-2"></div>

                    <div class="dropdown header-item topbar-user">
                        <button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <img class="rounded-circle header-profile-user" :src="profile_image" alt="Header Avatar" />
                                <span class="text-start ms-xl-2">
                                    <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ adminRec.firstname + " " + adminRec.lastname }}</span>
                                    <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text">Administrator</span>
                                </span>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <!-- item-->
                            <h6 class="dropdown-header">Welcome {{ adminRec.firstname }}!</h6>
                            <a class="dropdown-item" href="javascript:void(0)" @click="onShowProfileModal"><i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Profile</span></a>
                            <a class="dropdown-item" href="javascript:void(0)" @click="onLogout"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Logout</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <ProfileModal :admin="admin" @close="onCloseModal"></ProfileModal>
</template>

<script>
import ProfileModal from "./profile.vue";

export default {
    components: { ProfileModal },

    data() {
        return {
            admin: {},
        };
    },

    computed: {
        adminRec() {
            return this.$store.state.adminRecord;
        },
        profile_image() {
            if (this.adminRec.image) {
                return `/public/uploads/administrator/${this.adminRec.original_id}/thumb/${this.adminRec.image}`;
            }
        },
    },

    methods: {
        onShowProfileModal() {
            $("#profile_modal").modal("show");
            this.admin = this.adminRec;
        },
        onCloseModal() {
            $("#profile_modal").modal("hide");
        },

        onLogout() {
            sessionStorage.clear();
            window.location.href = "/admin";
        },
    },
};
</script>
