<template>
    <div class="loader" v-if="is_loading"></div>
    <div class="modal fade" id="azure_modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content px-3">
                <div class="modal-header bg-white">
                    <h5 class="modal-title mb-3" id="selectBlueprintModalLabel">Navbar Data</h5>
                    <button type="button" class="btn-close mb-2" aria-label="Close" @click="onCloseModal"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmitForm">
                        <div class="row g-3">
                            <div class="col-lg-12">
                                <div>
                                    <label for="app_id" class="form-label">App ID</label>
                                    <input v-model="app_id" type="text" class="form-control" id="app_id" />
                                    <span class="text-danger fs-12">{{ app_id_error }}</span>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-12">
                                <div>
                                    <label for="app_password" class="col-form-label">App Password</label>
                                    <input v-model="app_password" class="form-control" id="app_password" />
                                    <span class="text-danger fs-12">{{ app_password_error }}</span>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label for="scope" class="col-form-label">Scope</label>
                                    <select :id="scope" v-model="scope" id="scope" class="form-select">
                                        <option>User.Read</option>
                                        <option>Mail.Read</option>
                                        <option>Calendars.Read</option>
                                        <option>Contacts.Read</option>
                                        <option>Files.Read</option>
                                        <option>Tasks.Read</option>
                                    </select>
                                    <span class="text-danger fs-12">{{ scope_error }}</span>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="d-flex justify-content-end align-items-center">
                                <button class="btn_cms btn_cms-dark d-inline small-font px-3 py-2 fw-medium me-3" type="button" @click="onCloseModal">Cancel</button>
                                <button class="btn_cms btn_cms-save d-inline small-font px-4 py-2 fw-medium">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["is_click"],
    emits: ["call_azure"],
    data: function () {
        return {
            is_loading: false,
            app_id: "",
            app_password: "",
            scope: "User.Read",

            app_id_error: "",
            app_password_error: "",
            scope_error: "",
        };
    },

    methods: {
        onSubmitForm() {
            this.onClearErrors();
            this.is_loading = true;
            this.$query_admin("save_azure", {
                azure: {
                    app_id: this.app_id,
                    app_password: this.app_password,
                    scope: this.scope,
                    action_type: "add_record",
                },
            }).then((res) => {
                this.is_loading = false;

                if (res.data.errors) {
                    let errors = Object.values(res.data.errors[0].extensions.validation).flat();
                    let errors_keys = Object.keys(res.data.errors[0].extensions.validation).flat();

                    this.app_id_error = errors_keys.some((q) => q == "azure.app_id") ? errors[errors_keys.indexOf("azure.app_id")] : "";
                    this.app_password_error = errors_keys.some((q) => q == "azure.app_password") ? errors[errors_keys.indexOf("azure.app_password")] : "";
                    this.scope_error = errors_keys.some((q) => q == "azure.scope") ? errors[errors_keys.indexOf("azure.scope")] : "";
                } else {
                    const response = res.data.data.azure;

                    if (response.error) {
                        this.$swal("Error", response.message, "error");
                        $("#azure_modal").modal("hide");
                    } else {
                        this.$swal("Success", response.message, "success");
                        this.onCloseModal();
                    }
                }
            });
        },

        onPopulateData() {
            this.is_loading = true;
            this.$query_admin("azure", {
                action_type: "display_credentials",
            })
                .then((res) => {
                    this.is_loading = false;
                    const response = res.data.data.azure;

                    if (response) {
                        this.app_id = response.app_id;
                        this.app_password = response.app_password;
                        this.scope = response.scope;
                    }

                    $("#azure_modal").modal("show");
                })
                .catch(() => {
                    this.is_loading = false;
                    this.$swal("Error!", this.global_error_message, "error");
                });
        },

        onClearErrors() {
            this.app_id_error = "";
            this.app_password_error = "";
            this.scope_error = "";
        },

        onCloseModal() {
            this.onClearErrors();
            this.$emit("call_azure", false);
            $("#azure_modal").modal("hide");
        },
    },

    watch: {
        is_click(val) {
            if (val) {
                this.onPopulateData();
            }
        },
    },
};
</script>
