const IndexPage = () => import(/* webpackChunkName: "js/1000" */ "./pages/web/index.vue");

const TemplatePage = () => import(/* webpackChunkName: "js/1001" */ "./pages/web/template.vue");
const AboutPage = () => import(/* webpackChunkName: "js/1002" */ "./pages/web/about.vue");
const ContactPage = () => import(/* webpackChunkName: "js/1003" */ "./pages/web/contact.vue");
const ServicesPage = () => import(/* webpackChunkName: "js/1004" */ "./pages/web/services.vue");
const BlogsPage = () => import(/* webpackChunkName: "js/1005" */ "./pages/web/blogs.vue");
const BlogsDetailsPage = () => import(/* webpackChunkName: "js/1006" */ "./pages/web/blogs_details.vue");
const EventsPage = () => import(/* webpackChunkName: "js/1007" */ "./pages/web/events.vue");
const PolicyPage = () => import(/* webpackChunkName: "js/1008" */ "./pages/web/policies.vue");
const CareersPage = () => import(/* webpackChunkName: "js/1009" */ "./pages/web/careers.vue");
const ThankyouPage = () => import(/* webpackChunkName: "js/1010" */ "./pages/web/thankyou.vue");
const PageNotFoundPage = () => import(/* webpackChunkName: "js/1011" */ "./pages/web/page_not_found.vue");
const PrivacyPolicyPage = () => import(/* webpackChunkName: "js/1012" */ "./pages/web/privacy_policy.vue");
const ProductsPage = () => import(/* webpackChunkName: "js/1013" */ "./pages/web/products.vue");

export default [
    { path: "/", name: "IndexPage", component: IndexPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    //{ path: "/services/:slug", name: "ServicesPage", component: ServicesPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/blogs", name: "BlogsPage", component: BlogsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/blogs/:slug", name: "BlogsDetailsPage", component: BlogsDetailsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/events", name: "EventsPage", component: EventsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/about", name: "AboutPage", component: AboutPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/policy", name: "PolicyPage", component: PolicyPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/careers", name: "CareersPage", component: CareersPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    // { path: "/about", name: "AboutPage", component: AboutPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/contact", name: "ContactPage", component: ContactPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/thankyou", name: "ThankyouPage", component: ThankyouPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/404", name: "PageNotFoundPage", component: PageNotFoundPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/privacy-policy", name: "PrivacyPolicyPage", component: PrivacyPolicyPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    //{ path: "/products/:slug", name: "ProductsPage", component: ProductsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },

    { path: "/:slug", name: "ServicesPage", component: ServicesPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/:slug/:product_slug", name: "ProductsPage", component: ProductsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: '/:pathMatch(.*)*', name: 'PageNotFound', component: PageNotFoundPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
];
