import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import { VueReCaptcha } from "vue-recaptcha-v3";
import  VueGoogleMaps  from '@fawmi/vue-google-maps'
import App from "./App.vue";
import { onDatatable, onSummerNote } from "./helper.js";
import web_routes from "./routes_web.js";
import query_front from "./queries.js";
import eventBus from "./eventBus.js"; // Import the event bus
import { ContentLoader } from 'vue-content-loader';
import dayjs from "dayjs";

// for stylings
// import "/resources/css/app.css";
// import "./bootstrap";
// import "toastify-js/src/toastify.css";
import "sweetalert2/dist/sweetalert2.min.css";

let allRoutes = [];
allRoutes = allRoutes.concat(web_routes);

// creating the router and reading the routes file
const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes,
});

router.beforeEach((to, from, next) => {
    eventBus.emit("call_loader", true);

    // ADMIN
    if (to.matched.some((m) => m.meta.isAdminAuthentication === true)) {
        /*  if (to.matched.some((m) => m.meta.hasAdminAccess === false)) {
            window.location.href = "/admin/dashboard";
        } */
        if (sessionStorage.getItem("admin_api_token") === null || sessionStorage.getItem("login_type") === null) {
            window.location.href = "/admin";
            sessionStorage.clear();
            return;
        }
    } else {
        if (to.matched.some((m) => m.meta.requiresAuth === false)) {
            if (sessionStorage.getItem("login_type") != null) {
                sessionStorage.clear();
                window.location.href = "/admin";
            }
        }
    }
    next();
});

router.afterEach(() => {
    eventBus.emit("hide_loader", false);
});

const vueApp = createApp(App);

vueApp.config.errorHandler = (err, vm, info) => {
  console.error('Global Error Handler:', err, info);
};

//vueApp.config.globalProperties.$query_admin = query_admin;
vueApp.config.globalProperties.$query_front = query_front;
vueApp.config.globalProperties.$onDatatable = onDatatable;
vueApp.config.globalProperties.$onSummerNote = onSummerNote;
vueApp.config.globalProperties.$eventBus = eventBus;
vueApp.config.globalProperties.$formatDate = function(value) {
    if (value) {
        return dayjs(value).format("dddd, D MMM YYYY");
    }
};

vueApp.config.globalProperties.$onUpdateMetaTags = function(title, description, image, url) {
            document.title = title;
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[property="og:image"]').setAttribute('content', image);
            document.querySelector('meta[property="og:url"]').setAttribute('content', url);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
};

// mounting the app to the router
vueApp.use(VueReCaptcha, {
    siteKey: "6LfJ_TUqAAAAALv5fPtunLuoAq-F0rYEz1GGXxxQ",
    loaderOptions: {
        autoHideBadge: true, // Set this to false if you want to show the badge
    },
});
vueApp.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDcJxyN6lWL3OpDp03c0BrzgYOhauYg9mQ',
    }
});
vueApp.use(VueSweetalert2);
vueApp.use(ContentLoader);
vueApp.use(router);


vueApp.mount("#app");
