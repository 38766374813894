import axios from "axios";

let queries = {
    front_queries: `query front($action_type: String!, $slug: String) {
        front(action_type: $action_type, slug: $slug) {
            page {
                pageID,
                pageTitle,
                originalID,
                pageSlug,
                pageIsLive,
                pageMeta,
                pageHeader,
                dateCreated,
                page_sections {
                    pageSectionID,
                    pageSection_title,
                    pageSectionContent,
                    pageSectionPageID,
                    pageSectionPosition,
                }
            },
            pages {
                pageSlug,
                page_is_slug,
                pageTitle
            },
            partners {
              name,
              image,
              image_png
            },
            services {
              title,
              slug,
              brief_description,
              cover_image,
              cover_image_png,
              menu_icon,
              menu_subtitle,
            },
            testimonial {
              name,
              position,
              description,
              image,
              image_png
            },
            blogs {
              title,
              brief_description,
              cover_image,
              cover_image_png,
              slug,
              date_publish
            },
            service {
              title,
              subtitle,
              slug,
              description,
              image,
              image_png,
              meta,
              solutions {
                 title,
                 description,
                 image,
                 image_png
              },
              products {
                title,
                brief_description,
                description,
                cover_image,
                cover_image_png,
                slug
              }
            },

            blog {
              title,
              brief_description,
              image,
              image_png,
              slug,
              date_publish,
              description,
              meta
            },

            faq {
               question,
               answer
            },

            events {
              title,
              brief_description,
              cover_image,
              cover_image_png,
              slug,
              date_publish,
              links
            },

            event {
              title,
              brief_description,
              image,
              image_png,
              slug,
              date_publish,
              description
            },

            policy {
              title,
              brief_description,
              image,
              image_png,
              policy_file
            },

            department {
               name,
               careers {
                 position,
                 address
               }
            },

            menu {
              description,
              link,
              image,
              image_png
            },

            product {
              title,
              brief_description,
              cover_image,
              cover_image_png,
              landing_title,
              landing_subtitle,
              description,
              meta,
              faq {
                question,
                answer
              }
            }
        }
    }`,

    send_contact_us_email: `query front($action_type: String!, $name: String!, $email: String!, $subject: String!, $message: String!, $google_token: String!) {
        front(action_type: $action_type, name: $name, email: $email, subject: $subject, message: $message, google_token: $google_token) {
            error,
            message
        }
    }`,

    navbar_data: `query front($action_type: String!) {
    front(action_type: $action_type) {
            navbar {
                id,
                original_id,
                title,
                brief_description,
                cover_image,
                cover_image_png,
                title_one,
                slug_one,
                title_two,
                slug_two,
                title_three,
                slug_three
            },
            service {
                title,
                description,
                slug,
                cover_image,
                cover_image_png,
                menu_icon,
                menu_subtitle
            }
        }
    }`,

    action_leads: `mutation leads($leads: leads_input) {
        leads(leads: $leads) {
            error,
            message,
        }
    }`,

    action_newsletter: `mutation newsletter($newsletter: newsletter_input) {
        newsletter(newsletter: $newsletter) {
            error,
            message,
        }
    }`,

};

const query_front = (queryName, queryVariables) => {
    let options = {
        url: "/graphql",
        method: "POST",
        data: {
            query: queries[queryName],
        },
    };

    if (queryVariables) {
        options.data.variables = queryVariables;
    }
    return axios(options);
};

export default query_front;
